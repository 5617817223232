<section class="root our-companies-page mx-page">
  <header class="page-header">
    <h1 class="page-title">Our Companies</h1>
  </header>
  <div class="viewport" :class="{ 'align-right': isRight }">
    <a class="project" v-for="project in projects" :key="project.name" :href="project.link" target="_blank" rel="noopener nofollow">
      <div class="img-container">
        <div class="project-img">
          <img :src="`${project.img}.png`" :srcset="`${project.img}-2x.png 2x`" :alt="project.name" />
        </div>
        </div>
      <div>
        <h3 class="title">{{ project.name }}</h3>
        <div class="description">{{ project.description }}</div>
      </div>
    </a>
  </div>
</section>
