export default {
  data () {
    return {
      isRight: false,
      active: '',
      tunes: [
        {
          name: 'Bitcoin',
          video: '/static/video/bitcoin.mp4',
          poster: '/static/images/crypto/bitcoin.png',
        },
        {
          name: 'Rari',
          video: '/static/video/rari.mp4',
          poster: '/static/images/crypto/rari.png',
        },
        {
          name: 'Ethereum',
          video: '/static/video/ethereum.mp4',
          poster: '/static/images/crypto/ethereum.png',
        },
        {
          name: 'Time',
          video: '/static/video/time.mp4',
          poster: '/static/images/crypto/doge.png',
        }
      ]
    }
  },
  methods: {
    onVideoClick (name) {
      if (this.active && this.active !== name) {
        this.onVideoEnded(this.active, this.tunes.find(tune => tune.name === this.active).video)
      }
      if (this.active !== name) {
        this.active = name
        const video = this.$refs[name]
        if (video?.play) {
          video.play()
        }
      }
    },
    onVideoEnded (name, src) {
      const video = this.$refs[name]
      if (video) {
        video.src= src
        this.active = ''
      }
    },
  },
}
