import debounce from 'lodash/debounce'
import { disablePageScroll, enablePageScroll } from '@/utils/scrollLock'

export default {
  name: 'mx-header',
  data () {
    return {
      menu: false,
      tooltip: false,
    }
  },
  watch: {
    menu () {
      if (this.menu) {
        disablePageScroll()
      } else {
        enablePageScroll()
      }
    }
  },
  mounted () {
    window.addEventListener('resize', debounce(() => this.onResize(), 300))
  },
  methods: {
    onResize () {
      const width = Math.max(
        document.documentElement?.clientWidth || 0,
        window.innerWidth
      )
      if (width > 800 && this.menu) {
        this.menu = false
      }
    }
  },
}
