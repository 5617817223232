<div class="root music-arts">
  <div class="img-group">
    <img class="text-img mobile" src="/static/images/landing/text.png" alt="Music ArtNft" @load="onLoad" />
    <img class="text-img desktop" id="textImg" src="/static/images/landing/text.png" srcset="/static/images/landing/text-2x.png 2x" alt="Music ArtNft" @load="onLoad" />
    <img class="splash-img" id="splash" src="/static/images/landing/splash.png" srcset="/static/images/landing/splash-2x.png 2x" alt="Splash" @load="onLoad" />
    <img class="man-1-img" id="man1" src="/static/images/landing/man-1.png" srcset="/static/images/landing/man-1-2x.png 2x" alt="Man" @load="onLoad" />
    <img class="man-2-img" id="man2" src="/static/images/landing/man-2.png" srcset="/static/images/landing/man-2-2x.png 2x" alt="Man" @load="onLoad" />
    <img class="cursor-img" id="cursor" src="/static/images/landing/cursor.png" srcset="/static/images/landing/cursor-2x.png 2x" alt="Cursor" @load="onLoad" />
    <div class="text" id="text">One of the top NFT sellers and collectors on Rarible.</div>
    <img class="mobile-play-img" src="/static/images/play.png" srcset="/static/images/play-2x.png 2x" alt="Play" />
    <img class="red-man-2-img" src="/static/images/landing/red-man-2.png" srcset="/static/images/landing/red-man-2-2x.png 2x" @load="onLoad" />
  </div>
  <img class="play-img" id="play" src="/static/images/play.png" srcset="/static/images/play-2x.png 2x" alt="Play" />
  <img class="red-man-img" id="redman" src="/static/images/landing/red-man.png" srcset="/static/images/landing/red-man-2x.png 2x" @load="onLoad" />
</div>
