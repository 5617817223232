export default {
  data () {
    return {
      isRight: false,
      active: -1,
      projects: [
        {
          name: 'LaborX',
          description: 'Blockchain-based freelance jobs platform',
          img: '/static/images/companies/laborx',
          link: 'https://laborx.com/',
        },
        {
          name: 'TimeX',
          description: 'PlanPlasma-based hybrid cryptocurrency exchange',
          img: '/static/images/companies/timex1',
          link: 'http://timex.io/',
        },
        {
          name: 'PaymentX',
          description: 'Manage cryptocurrency payroll seamlessly',
          img: '/static/images/companies/paymentx',
          link: 'https://paymentx.io/',
        },
        {
          name: 'TimeWarp',
          description: 'Stake your TIME and earn ongoing rewards',
          img: '/static/images/companies/timewarp',
          link: 'https://timewarp.finance/',
        },
        {
          name: 'MHC Digital Finance',
          description: 'Institutional digital asset investment & Custody',
          img: '/static/images/companies/mhc',
          link: 'https://mhcdigitalfinance.com/',
        },
        {
          name: 'AUDT',
          description: 'Stablecoin linked to Australian dollar',
          img: '/static/images/companies/audt',
          link: 'https://audt.to/',
        },
        {
          name: 'stbl.fund',
          description: 'S/F DEFI token',
          img: '/static/images/companies/stbl-fund',
          link: 'https://stbl.fund/',
        },
      ]
    }
  }
}
