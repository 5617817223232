const isSmall = () => window.innerWidth <= 1440

export default {
  name: 'mx-header',
  data () {
    return {
      loaded: 0,
    }
  },
  computed: {
    isIndex () {
      return this.$route.name === 'Index'
    },
  },
  methods: {
    onLoad () {
      this.loaded += 1
      if (this.loaded >= 7) {
        this.$emit('loaded')
      }
    },
    setTransform () {
      if (window.innerWidth > 700 && !this.isIndex) {
        this.moveTextImg()
        this.moveMan1()
        this.moveMan2()
        this.moveCursor()
        this.moveText()
        this.movePlay()
        this.moveRedman()
      }
    },
    resetTransform () {
      ['#textImg', '#man1', '#man2', '#cursor', '#text', '#redman', '#play'].forEach(id => {
        const img = this.$el.querySelector(id)
        if (img) {
          img.style.transform = ''
        }
      })
    },
    moveTextImg () {
      if (isSmall()) {
        this.move({ img: '#textImg', w: 375, h: 165, l: 24, b: 56 })
      } else {
        this.move({ img: '#textImg', w: 526.3, h: 231.46, l: 97, b: 79.96 })
      }
    },
    moveMan1 () {
      if (isSmall()) {
        this.move({ img: '#man1', w: 90.67, h: 104.77, l: 251.73, b: 165.67 })
      } else {
        this.move({ img: '#man1', w: 127.23, h: 146.98, l: 416.62, b: 233.8 })
      }
    },
    moveMan2 () {
      if (isSmall()) {
        this.move({ img: '#man2', w: 45.13, h: 52.39, l: 229.82, b: 197 })
      } else {
        this.move({ img: '#man2', w: 63.33, h: 73.49, l: 385.86, b: 276.35 })
      }
    },
    moveCursor () {
      if (isSmall()) {
        this.move({ img: '#cursor', w: 53, h: 53, l: 160, b: 14.06, deg: 45 })
      } else {
        this.move({ img: '#cursor', w: 74.37, h: 74.37, l: 287.87, b: 19.7, deg: 45 })
      }
    },
    moveText () {
      if (isSmall()) {
        this.move({ img: '#text', w: 137.28, h: 87.27, l: 350, b: 11.28, deg: -12 })
      } else {
        this.move({ img: '#text', w: 192.27, h: 121.56, l: 554.63, b: 19.9, deg: -12 })
      }
    },
    movePlay () {
      if (isSmall()) {
        this.move({ img: '#play', w: 72, h: 24, l: 101, b: 36.55, deg: -12 })
      } else {
        this.move({ img: '#play', w: 101.05, h: 33.67, l: 205.07, b: 51.28, deg: -12 })
      }
    },
    moveRedman () {
      if (isSmall()) {
        this.move({ img: '#redman', w: 340, h: 103.53, l: 75, b: 0 })
      } else {
        this.move({ img: '#redman', w: 477.18, h: 159.33, l: 168.58, b: 0 })
      }
    },
    move ({ img = '', w = 0, h = 0, l = 0, b = 0, deg = 0 }) {
      const imgEl = document.getElementById('safe-art').querySelector(img)
      const targetImgEl = this.$el.querySelector(img)
      if (imgEl) {
        if (!this.isIndex) {
          const rect = imgEl.getBoundingClientRect()
          const tW = w / rect.width
          const tH = h / rect.height
          const tX = l - (rect.width - w) / 2 - rect.left
          const top = rect.top + window.scrollY + (rect.height - h) / 2
          const pageContainerHeight = document.getElementById('app').querySelector('.mx-page')?.clientHeight || 0
          const headerHeight = document.getElementById('app').querySelector('.mx-header')?.clientHeight || 0
          const pageHeight = headerHeight + pageContainerHeight
          const fTop = pageHeight - b - h
          const tY = fTop - top
          let transform = `matrix(${tW}, 0, 0, ${tH}, ${tX}, ${tY})`
          if (deg) {
            transform = `${transform} rotate(${deg}deg)`
          }
          targetImgEl.style.transform = transform
        } else {
          targetImgEl.style.transform = ''
        }
      }
    },
  }
}
