<section class="root contact-us-page mx-page">
  <header class="page-header">
    <h1 class="page-title">Contact Us</h1>
  </header>
  <div class="viewport">
    <div class="step succes-step" v-if="success">
      <div class="img-container">
        <img class="cursor-img" src="/static/images/landing/cursor.png" srcset="/static/images/landing/cursor-2x.png 2x" alt="Cursor" />
        <img class="smile-img" src="/static/images/smile.png" srcset="/static/images/smile-2x.png 2x" alt="Smile" />
      </div>
      <div class="primary-text">㋡ Thank you for getting in touch!</div>
      <div class="secondary-text">thanks for reaching out!We’re thrilled to hear from you. Our inbox can’t wait to get your messages, so talk to us any time you like.</div>
      <mx-button class="btn" @click="$router.push('/')">GOT IT</mx-button>
    </div>
    <div class="step error-step" v-else-if="error">
      <div class="img-container">
        <img src="/static/images/error.png" srcset="/static/images/error-2x.png 2x" alt="Error" />
      </div>
      <div class="primary-text">(⌣̩̩́_⌣̩̩̀) Something went wrong...</div>
      <div class="secondary-text">try again in a few seconds</div>
      <mx-button class="btn" @click="error = false">OKAY</mx-button>
    </div>
    <form class="step fill-step" v-else @submit.prevent="onSubmit">
      <mx-input class="input" label="Name" v-model="name" />
      <mx-input class="input" label="Email" v-model="email" type="email" />
      <mx-textarea class="input" label="Add a message" v-model="message" />
      <mx-button class="btn" type="submit" :disabled="submitDisabled" :loading="loading">SUBMIT</mx-button>
    </form>
  </div>
</section>
