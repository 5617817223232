export default {
  name: 'mx-textarea',
  emits: ['update:modelValue'],
  props: {
    modelValue: String,
    label: String,
  },
  mounted () {
    this.calculateInputHeight()
  },
  methods: {
    onSelfClick () {
      this.$refs.input.focus()
    },
    onInput (e) {
      this.calculateInputHeight()
      this.$emit('update:modelValue', e?.target?.value)
    },
    calculateInputHeight () {
      const input = this.$refs.input
      if (!input) return

      input.style.height = '0'
      const height = input.scrollHeight
      const minHeight = 30
      // This has to be done ASAP, waiting for Vue
      // to update the DOM causes ugly layout jumping
      input.style.height = Math.max(minHeight, height) + 'px'
    },
  },
}
