export function setMeta ({ title = '', postfix = '| MusicX', ogTitle = title, ogImage } = {}) {
  document.title = title ? `${title} ${postfix}` : 'MusicX'
  setOGMeta('title', ogTitle)
  if (ogImage) {
    setOGMeta('image', ogImage)
  }
}

function setOGMeta (type, content) {
  const ogTitleEl = document.querySelector(`meta[property="og:${type}"]`)
  if (ogTitleEl) {
    ogTitleEl.setAttribute('content', content)
  }
}
