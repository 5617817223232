<mx-header class="header" ref="header" />
<div class="page-container" ref="pageContainer">
  <router-view v-slot="{ Component }">
    <transition name="fade" @after-enter="onPageAfterEnter">
      <component :is="Component" @loaded="updateArtPostion"></component>
    </transition>
  </router-view>
</div>
<footer :class="{ 'index-footer': isIndex }">
  <div class="background" :class="{ 'align-right': isCrypto }"></div>
  <music-art :class="{ 'as-footer': !isIndex }" :style="{ opacity: musicOpacity }" ref="art" @loaded="onLoadMusicImg" />
  <music-art :style="{ opacity: 0, transitionDuration: '0s' }" id="safe-art" ref="art1" />
</footer>
<mx-footer class="footer" ref="mxFooter" />
