<component
  class="root mx-button"
  :is="tag"
  v-bind="$attrs"
  :to="to"
  :disabled="isDisabled"
  :loading="loading"
>
  <mx-loader class="loader" v-if="loading" />
  <div class="btn-content">
    <mx-icon v-if="icon" :icon="icon"></mx-icon>
    <slot></slot>
  </div>
</component>
