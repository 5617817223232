import { createRouter, createWebHistory } from 'vue-router'
import Index from '@/pages/Index/Index.vue'
import CryptoTunes from '@/pages/CryptoTunes/CryptoTunes.vue'
import Portfolio from '@/pages/Portfolio/Portfolio.vue'
import OurCompanies from '@/pages/OurCompanies/OurCompanies.vue'
import ContactUs from '@/pages/ContactUs/ContactUs.vue'
import { setMeta } from '@/utils/meta'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      meta: {
        title: 'Music. Art. Creative. Audio Visual NFT',
        ogImage: 'https://musicx.com.au/static/images/og/index.png',
      },
    }
  },
  {
    path: '/cryptotunes',
    name: 'cryptotunes',
    component: CryptoTunes,
    meta: {
      meta: {
        title: 'Crypto Tunes',
        ogImage: 'https://musicx.com.au/static/images/og/crypto-tunes.png',
      },
    }
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: Portfolio,
    meta: {
      meta: {
        title: 'Portfolio',
        ogImage: 'https://musicx.com.au/static/images/og/portfolio.png',
      },
    }
  },
  {
    path: '/our-companies',
    name: 'our-companies',
    component: OurCompanies,
    meta: {
      meta: {
        title: 'Our Companies',
        ogImage: 'https://musicx.com.au/static/images/og/our-companies.png',
      },
    }
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUs,
    meta: {
      meta: {
        title: 'Contact Us',
        ogImage: 'https://musicx.com.au/static/images/og/contact-us.png',
      },
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.afterEach((to) => {
  setMeta(to.meta.meta)
})

export default router
