export default {
  data () {
    return {
      isRight: false,
      active: 0,
      loaded: 0,
      portfolio: [
        {
          name: 'Bitcoin Queen',
          description: 'The embodiment of the power and beauty that Bitcoin is in the world of cryptocurrency',
          img: '/static/images/portfolio/bitcoin-queen',
          cost: '15.89 WETH',
          usdCost: '$39,830.14',
          link: 'https://rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:524382:0xd2134a87dada655bb288fd0ebf3d97ec6a5849c3'
        },
        {
          name: 'Melting Mars',
          description: '"Melting Mars" is the 2nd of 4 Planets',
          img: '/static/images/portfolio/melting-mars',
          cost: '19.99 WETH',
          usdCost: '$50,129.83',
          link: 'https://rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:283496:0x3a936378b59504a7db8054ae6b4ce000bdc720b9'
        },
        {
          name: 'Fallen Universe',
          description: '"Fallen Universe" is the Last of 4 Planets',
          img: '/static/images/portfolio/fallen-universe',
          cost: '18.50 WETH',
          usdCost: '$46,372.41',
          link: 'https://rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:284459:0x3a936378b59504a7db8054ae6b4ce000bdc720b9?'
        },
        {
          name: 'Faceless Ethereum Haunt',
          description: 'Limited Edition Faceless Ethereum Haunt',
          img: '/static/images/portfolio/faceless-ethereum',
          cost: '15.89 WETH',
          usdCost: '$39,830.14',
          link: 'https://rarible.com/token/0xd07dc4262bcdbf85190c01c996b4c06a461d2430:350255:0x8e288c8a67f266d5c8b95bf5c4c02f9bf25e55bc'
        },
        {
          name: 'RAT - First Anomaly',
          description: 'Can crash any electronic device within 5 meters and create glitched clones of themselves that can disappear on will.',
          img: '/static/images/portfolio/rat',
          cost: '15.89 WETH',
          usdCost: '$39,830.14',
          link: 'https://rarible.com/token/0xd07dc4262bcdbf85190c01c996b4c06a461d2430:451400:0x8e288c8a67f266d5c8b95bf5c4c02f9bf25e55bc'
        },
      ]
    }
  },
  methods: {
    onImgLoad () {
      this.loaded += 1
      if (this.loaded >= this.portfolio.length) {
        this.$emit('loaded')
      }
    },
    onClickLeft () {
      this.active = Math.max(this.active - 1 , 0)
    },
    onClickRight () {
      const limit = window.innerWidth <= 1440 ? 2 : 1
      this.active = Math.min(this.active + 1 , limit)
    }
  },
}
