<nav class="root mx-footer">
  <div class="text">WE ARE HERE</div>
  <a class="rarible link" href="https://rarible.com/musicx" target="_blank" rel="noopener nofollow">
    <img src="/static/images/footer/rarible.png" srcset="/static/images/footer/rarible-2x.png 2x" alt="Rarible" />
    <div>RARIBLE</div>
    <div class="icon-container">
      <mx-icon class="icon" icon="chevron" />
      <div class="movable-icon-container">
        <mx-icon class="icon movable-icon" icon="chevron" />
        <mx-icon class="icon movable-icon" icon="chevron" />
      </div>
    </div>
  </a>
  <div class="social-links">
    <a href="https://www.facebook.com/MusicX-101151682039042" target="_blank" rel="noopener nofollow">
      <mx-icon class="icon facebook-icon" icon="facebook" />
    </a>
    <a href="https://twitter.com/musicxnft" target="_blank" rel="noopener nofollow">
      <mx-icon class="icon twitter-icon" icon="twitter" />
    </a>
    <a href="https://www.instagram.com/officialmusicx" target="_blank" rel="noopener nofollow">
      <mx-icon class="icon instagram-icon" icon="instagram" />
    </a>
    <a href="https://rarible.com/musicx" target="_blank" rel="noopener nofollow">
      <img class="icon mobile-icon" src="/static/images/footer/rarible.png" srcset="/static/images/footer/rarible-2x.png 2x" alt="Rarible" />
    </a>
    <a href="https://foundation.app/musicx" target="_blank" rel="noopener nofollow">
      <img class="icon mobile-icon" src="/static/images/footer/foundation.png" srcset="/static/images/footer/foundation-2x.png 2x" alt="Rarible" />
    </a>
  </div>
  <a class="foundation link" href="https://foundation.app/musicx" target="_blank" rel="noopener nofollow">
    <img src="/static/images/footer/foundation.png" srcset="/static/images/footer/foundation-2x.png 2x" alt="Rarible" />
    <div>FOUNDATION</div>
    <div class="icon-container">
      <mx-icon class="icon" icon="chevron" />
      <div class="movable-icon-container">
        <mx-icon class="icon movable-icon" icon="chevron" />
        <mx-icon class="icon movable-icon" icon="chevron" />
      </div>
    </div>
  </a>
</nav>
