import { sendFeedback } from '@/services/remotes'

export default {
  data () {
    return {
      name: '',
      email: '',
      message: '',
      loading: false,
      success: false,
      error: false,
    }
  },
  computed: {
    submitDisabled () {
      return !(this.name && this.email && this.message)
    }
  },
  methods: {
    async onSubmit () {
      try {
        if (!this.submitDisabled) {
          this.loading = true
          await sendFeedback({ name: this.name.trim(), email: this.email.trim(), message: this.message.trim() })
          this.success = true
        }
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        this.loading = false
      }
    }
  },
}
