import Button from './Button/Button.vue'
import Icon from './Icon/Icon.vue'
import Input from './Input/Input.vue'
import Loader from './Loader/Loader.vue'
import Textarea from './Textarea/Textarea.vue'
import Textfield from './Textfield/Textfield.vue'

const components = [Button, Icon, Input, Loader, Textarea, Textfield]

export default {
  install: (Vue) => {
    for (const component of components) {
      if (component.name) {
        Vue.component(component.name, component)
      }
    }
  },
}
