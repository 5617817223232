const BACKEND = process.env.VUE_APP_BACKEND_API

export async function sendFeedback ({ name, email, message }) {
  return new Promise((resolve, reject) => {
    fetch(`${BACKEND}feedback/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
        payload: { 'site_id': 'musicx.com.au', name, email, message }
      })
    })
    .then(res => res.ok ? resolve(res) : reject(res))
    .catch(err => reject(err))
  })
}
