<nav class="root mx-header">
  <div class="top-panel">
    <router-link class="logo" to="/" @click="menu = false">
      <img src="/static/images/logo.png" srcset="/static/images/logo-2x.png 2x" alt="musicx" />
    </router-link>
    <div class="side-right">
      <mx-button class="link" to="cryptotunes">CRYPTO TUNES</mx-button>
      <mx-button class="link" to="portfolio">PORTFOLIO</mx-button>
      <mx-button class="link" to="our-companies">OUR COMPANIES</mx-button>
      <mx-button class="link" to="contact-us">CONTACT US</mx-button>
      <div class="order-btn-container">
        <mx-button
          class="order-btn"
          ref="orderBtn"
          href="https://laborx.com/gigs/nft" 
          target="_blank"
          rel="noopener nofollow"
          @mouseenter.native="tooltip = true"
          @mouseleave.native="tooltip = false"
        >
          <span>ORDER NFT</span>
        </mx-button>
        <transition name="grow" type="animation">
          <div class="tootlip" v-if="tooltip">
            <div class="tootlip-content">You can order NTF from our designers on the blockchain freelance platform LaborX</div>
          </div>
        </transition>
      </div>
    </div>
    <transition-group name="btn">
      <button class="mobile-menu-btn close-btn" key="open" v-if="menu" @click="menu = !menu">
        <mx-icon class="icon" icon="arrow-back" />
      </button>
      <button class="mobile-menu-btn open-btn" key="close" v-else @click="menu = !menu">
        <mx-icon class="icon" icon="menu" />
      </button>
    </transition-group>
  </div>
  <transition name="fade">
    <div class="mobile-nav" :class="menu ? 'menu-open' : 'menu-close'" v-if="menu">
      <mx-button class="link" to="cryptotunes" @click="menu = false">CRYPTO TUNES</mx-button>
      <mx-button class="link" to="portfolio" @click="menu = false">PORTFOLIO</mx-button>
      <mx-button class="link" to="our-companies" @click="menu = false">OUR COMPANIES</mx-button>
      <mx-button class="link" to="contact-us" @click="menu = false">CONTACT US</mx-button>
      <mx-button class="order-btn link" href="https://laborx.com/gigs/nft" @click="menu = false">ORDER NFT</mx-button>
      <div class="social-links">
        <div class="text">WE ARE HERE</div>
        <div class="links">
          <mx-icon class="icon facebook-icon" icon="facebook" />
          <mx-icon class="icon twitter-icon" icon="twitter" />
          <mx-icon class="icon instagram-icon" icon="instagram" />
          <img class="icon" src="/static/images/footer/rarible.png" srcset="/static/images/footer/rarible-2x.png 2x" alt="Rarible" />
          <img class="icon" src="/static/images/footer/foundation.png" srcset="/static/images/footer/foundation-2x.png 2x" alt="Rarible" />
        </div>
      </div>
    </div>
  </transition>
</nav>
