export default {
  name: 'mx-input',
  emits: ['update:modelValue'],
  props: {
    modelValue: String,
    label: String,
    type: {
      type: String,
      default: 'text',
    }
  },
  methods: {
    onSelfClick () {
      this.$refs.input.focus()
    },
    onInput (e) {
      this.$emit('update:modelValue', e?.target?.value)
    }
  },
}
