import get from 'lodash/get'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'
import throttle from 'lodash/throttle'
import MusicArt from '@/partials/MusicArt/MusicArt.vue'
import MxHeader from '@/partials/Header/Header.vue'
import MxFooter from '@/partials/Footer/Footer.vue'

const ANIM_DUR = '.3s'
export default {
  components: {
    MusicArt,
    MxHeader,
    MxFooter,
  },
  data () {
    return {
      footerHeight: 0,
      musicOpacity: 0,
      artLoaded: false,
    }
  },
  computed: {
    isIndex () {
      return !this.$route.name || this.$route.name === 'Index'
    },
    isCrypto () {
      return this.$route.name === 'cryptotunes'
    },
  },
  watch: {
    $route (newR, oldR) {
      const art  = this.$refs.art?.$el
      if (newR.name === 'Index') {
        this.$nextTick(() => {
          this.calcFooter()
          art.style.transitionProperty = 'all'
          art.style.transitionDuration = ANIM_DUR
          this.$refs.art.resetTransform()
        })
      } else if (oldR.name === 'Index') {
        setTimeout(() => {
          this.calcFooter()
          art.style.transitionProperty = 'all'
          art.style.transitionDuration = ANIM_DUR
          this.updateArtPostion()
        }, 100)
      } else {
        this.musicOpacity = 0
        art.style.transitionDuration = '0s'
        setTimeout(() => {
          art.style.transitionProperty = 'opacity'
          this.updateArtPostion()
          this.musicOpacity = 1
        }, 400)
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
  },
  methods: {
    updateArtPostion () {
      if (this.artLoaded) {
        this.$refs.art.setTransform()
      }
    },
    onLoadMusicImg () {
      this.artLoaded = true
      this.calcFooter()
      setTimeout(() => {
        this.$refs.art.setTransform()
        this.musicOpacity = 1
      }, 1000)
    },
    calcFooter () {
      const art  = get(this.$refs, 'art1.$el')
      const headerHeight = get(this.$refs, 'header.$el.clientHeight', 0)
      if (art) {
        const app = document.getElementById('app')
        let footerHeight = 404
        if (!this.isIndex) {
          app.style.height = 'auto'
          app.style.minHeight = '100%'
        } else {
          app.style.height = '100%'
          if (this.artLoaded) {
            const imgs = [...art.querySelectorAll('.img-group > img')]
            const rects = imgs.map(img => img.getBoundingClientRect()).filter(img => img.width)
            const min = minBy(rects, 'top')
            const max = maxBy(rects, 'bottom')
            footerHeight = max.bottom - min.top
          }
          let offset = 160
          if (!this.isIndex) {
            offset = 0
          } else if (window.innerWidth <= 700) {
            offset = 200
          }  else if (window.innerWidth <= 834) {
            offset = 400
          } else if (window.innerWidth <= 1000) {
            offset = 250
          }
          app.style.minHeight = `${headerHeight + footerHeight + offset}px`
        }
      }
    },
    onResize: throttle(function () {
      this.calcFooter()
      const art  = this.$refs.art?.$el
      if (art) {
        art.style.transitionDuration = '0s'
      }
      this.updateArtPostion()
    }, 300),
    onPageAfterEnter () {
      this.updateArtPostion()
    }
  },
}
