export default {
  name: 'mx-button',
  props: {
    to: [Object, String],
    icon: String,
    disabled: Boolean,
    loading: Boolean,
  },
  computed: {
    tag () {
      if (this.to) {
        return 'router-link'
      }

      if (this.$attrs.href) {
        return 'a'
      }

      return 'button'
    },
    isDisabled () {
      return Boolean(this.disabled || this.loading)
    }
  },
}
