<section class="root portfolio-page mx-page">
  <header class="page-header">
    <h1 class="page-title">Portfolio</h1>
    <div class="btns-container">
      <mx-button class="btn" icon="arrow-left" @click="onClickLeft"></mx-button>
      <mx-button class="btn right-btn" icon="arrow-left" @click="onClickRight"></mx-button>
    </div>
  </header>
  <div class="viewport" :classs="{ 'align-right': isRight }" :class="`step-${active}`">
    <a class="project" v-for="project in portfolio" :key="project.name" :href="project.link" target="_blank" rel="noopener nofollow">
      <div class="content">
        <div class="img-container">
          <img class="project-img" :src="`${project.img}.png`" :srcset="`${project.img}-2x.png 2x`" :alt="project.name" @load="onImgLoad" />
          <div class="cost-badge">
            <div class="cost">{{ project.cost }}</div>
            <div class="usd-cost">{{ project.usdCost }}</div>
          </div>
        </div>
        <h3 class="title">{{ project.name }}</h3>
        <div class="description">{{ project.description }}</div>
      </div>
    </a>
  </div>
</section>
