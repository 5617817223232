<section class="root crypto-tunes-page mx-page">
  <header class="page-header">
    <h1 class="page-title">Crypto Tunes</h1>
    <div class="btns-container">
      <mx-button class="btn" icon="arrow-left" @click="isRight = false"></mx-button>
      <mx-button class="btn right-btn" icon="arrow-left" @click="isRight = true"></mx-button>
    </div>
  </header>
  <div class="viewport" :class="{ 'align-right': isRight }">
    <div class="item" v-for="tune in tunes" :key="tune.name" :class="{ active: tune.name === active }">
      <video
        preload="none"
        :controls="active === tune.name && 'controls'"
        :poster="tune.poster"
        :src="tune.video"
        :ref="tune.name"
        @click="onVideoClick(tune.name)"
        @ended="onVideoEnded(tune.name, tune.video)"
      >
      </video>
      <div class="title">{{ tune.name }}</div>
      <img class="play-img" src="/static/images/play.png" srcset="/static/images/play-2x.png 2x" alt="Play" />
    </div>
  </div>
</section>
